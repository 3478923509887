import React from "react";
import pipes from "../../../static/utilities/pipes.jpg";
import plant from "../../../static/utilities/plant.jpg";
import mountains from "../../../static/utilities/mountains.jpg";
import water from "../../../static/utilities/water.jpg";
import alternativeEnergy from "../../../static/utilities/alternative-energy.jpg";
import gas from "../../../static/utilities/gas.jpg";
import motorola from "../../../static/partners/motorola.png";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";

import Layout from "../../components/Layout/Layout";

const Utilities = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Utilities</h1>
            <p className="section-description">
              Our Company has a long-standing expertise in pushing the
              technological boudaries of the utilities sector. We have automated
              numerous industrial processes in water, electricity, gas and
              heating.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // textAlign: 'center',
            }}
          >
            <i
              className="icon icon-lightbulb"
              style={{ fontSize: "20rem", color: "#112a59" }}
            />
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                Building the utilities of tomorrow
              </h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                S&T Moldova has been a long-time partner with the major
                utilities suppliers in Moldova. We have helped them modernize
                and become more efficient in order to stand up to the challanges
                of the era.
              </p>
              <p className="section-description">
                Our services include consulting, automating processes,
                implementing new technologies through the best SCADA systems
                available. Together with our partners we have been advancing
                utilities to new levels of efficiency, security, sustainability.
              </p>
              <p className="section-description">
                We are dedicated to building a better, greener and safer future
                for our planet through technological progress and we have
                executed our vision with tenacity in the utilities sector. With
                the help of our clients and parteners we are driving the sector
                towards greener sources of energy and more sustainable
                processes.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                className="section-description-image"
                src={alternativeEnergy}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="block--white">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">Our work in this sector</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={pipes} alt="PIPES" />
                <div className="studies-tile__content">
                  <span href="/" className="studies-title">
                    Modern Utilities
                  </span>
                  <p className="section-title-small --align-left ">
                    Natural Gas
                  </p>
                  <p className="section-description --align-left ">
                    We have been working together with MoldovaGaz - the local
                    gas company on Back-Office and Front-Office automation and
                    also maintaining and improving their older systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={water} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Modern Utilities</span>
                  <p className="section-title-small --align-left ">
                    Water Industry
                  </p>
                  <p className="section-description --align-left ">
                    We have been working with Apa-Canal - the local water
                    utilities company on automating processes through SAP and
                    Microsoft Dynamics technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-50"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={gas} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Modern Utilities</span>
                  <p className="section-title-small --align-left ">
                    Thermal Power
                  </p>
                  <p className="section-description --align-left ">
                    We have a long-standing relationship with Termoelectrica and
                    CETs on implementing SCADA systems for their critical
                    processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block--gray">
          <div className="row  page__section --grid-2-1 --align-left">
            <div>
              <h1 className="section-sub-title">
                Working towards a greener future
              </h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-45"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={mountains} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Saving the Planet</span>
                  <p className="section-title-small --align-left ">
                    Clean technologies
                  </p>
                  <p className="section-description --align-left ">
                    Together we must advance technologies towards new milestones
                    in minimizing the impact our utilities have on the
                    envitonment. We stand by this goal and so do our clients.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-45"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={plant} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Saving the Planet</span>
                  <p className="section-title-small --align-left ">
                    Efficient solutions
                  </p>
                  <p className="section-description --align-left ">
                    Using newst hardware and software we help push for more
                    efficient operations which contributes to lower
                    environmental impact.
                  </p>
                  {/* <a href="/" className="learn-more">
                    LEARN MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={motorola}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Motorola is the leading global supplier of TETRA equipment
                    and is our partner in Moldova. We supply Motorola equipment
                    to the Police, Border Police, Customs, 112 service, and
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Utilities;
